import Api from "../apis/Api";

export default {
  data: function () {
    return {
      controlpanelSsoLoading: false,
      controlpanelSsoUrl: "",
    };
  },
  methods: {
    controlPanelSsoLogin(hostingAccountId) {
      if (this.controlpanelSsoLoading) {
        return;
      }
      this.controlpanelSsoLoading = true;
      Api.post(`/server-accounts/${hostingAccountId}/control-panel-sso-url`).then((response) => {
        window.open(response.data.data.url);
      }).catch((error) => {
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: Api.getErrorMessage(error),
        });
      }).finally(() => {
        this.controlpanelSsoLoading = false;
      });
    },
  },
}
